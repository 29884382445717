<template>
  <div class="main">
    <el-card class="card" shadow="always">
      <h3 class="line">{{ line1 }}</h3>
      <h3 class="line">{{ line2 }}</h3>
    </el-card>
  </div>
  <el-button v-if="lastJoke" class="button" @click="onLast">last joke</el-button>
  <el-button class="button" @click="onClick">Change</el-button>
</template>

<script setup>
import { ref } from 'vue'
import jokes from '../assets/jokes'

const line1 = ref('')
const line2 = ref('')

let lastJoke = ref(null)

const init = () => {
  if (line1.value !== '' || line2.value !== '') {
    lastJoke.value = { line1: line1.value, line2: line2.value }
    console.log('lastJoke', lastJoke)
  }
  const joke = jokes[Math.floor(Math.random() * jokes.length)]
  line1.value = joke.line1 || ''
  line2.value = joke.line2 || ''
}


const onClick = () => {
  init()
}

const onLast = () => {
  if (lastJoke.value !== null && (lastJoke.value.line1 !== '' || lastJoke.value.line2 !== '')) {
    line1.value = lastJoke.value.line1 || ''
    line2.value = lastJoke.value.line2 || ''
  }
}

init()

</script>
<style scoped>
.main {
  display: flex;
  justify-content: center;
}

.card {
  width: calc(100% - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #FFF3B0; /* 柔和的黄色背景，与绿色背景形成温暖对比 */
  color: #2C3E50; /* 深色文字，确保可读性 */
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* 柔和阴影，增强立体感 */
}

.line {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #D1495B; /* 暖色调的红色，与黄色背景形成对比，活泼而不突兀 */
}

.button {
  margin-top: 20px;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background-color: #1D3557; /* 深蓝色按钮，与绿色背景形成冷暖对比 */
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #457B9D; /* 按钮悬停效果，浅蓝色，提供视觉反馈 */
}
</style>