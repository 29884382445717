<template>
  <div class="background-overlay" />
  <h2>Tell me a joke!</h2>
  <JokePage/>
</template>

<script>
import JokePage from './components/JokePage.vue'

export default {
  name: 'App',
  components: {
    JokePage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  
}
.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/bg.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1; /* 将背景放在内容后面 */
  filter: blur(20px); /* 高斯模糊效果 */
}


#app h2, #app JokePage {
  position: relative;
  z-index: 1; /* 确保内容在模糊背景上方 */
}


</style>
