const jokes = [
  {
    "line1": "What do you call a pony with a cough?",
    "line2": "A little horse."
  },
  {
    "line1": "What did one hat say to the other?",
    "line2": "You wait here. I’ll go on a head."
  },
  {
    "line1": "What do you call a magic dog?",
    "line2": "A labracadabrador."
  },
  {
    "line1": "What did the shark say when he ate the clownfish?",
    "line2": "This tastes a little funny."
  },
  {
    "line1": "What’s orange and sounds like a carrot?",
    "line2": "A parrot."
  },
  {
    "line1": "Why can’t you hear a pterodactyl go to the bathroom?",
    "line2": "Because the “P” is silent."
  },
  {
    "line1": "What do you call a woman with one leg?",
    "line2": "Eileen."
  },
  {
    "line1": "What did the pirate say when he turned 80?",
    "line2": "Aye matey."
  },
  {
    "line1": "Why did the frog take the bus to work today?",
    "line2": "His car got toad away."
  },
  {
    "line1": "What did the buffalo say when his son left for college?",
    "line2": "Bison."
  },
  {
    "line1": "What is an astronaut’s favorite part on a computer?",
    "line2": "The space bar."
  },
  {
    "line1": "Why did the yogurt go to the art exhibition?",
    "line2": "Because it was cultured."
  },
  {
    "line1": "What do you call an apology written in dots and dashes?",
    "line2": "Re-Morse code."
  },
  {
    "line1": "Did you hear about the two people who stole a calendar?",
    "line2": "They each got six months."
  },
  {
    "line1": "Why did the hipster burn his mouth?",
    "line2": "He drank the coffee before it was cool."
  },
  {
    "line1": "What do cows do on date night?",
    "line2": "Go to the moo-vies."
  },
  {
    "line1": "What do cows say when they hear a bad joke?",
    "line2": "I am not amoosed."
  },
  {
    "line1": "Why do French people eat snails?",
    "line2": "They don’t like fast food."
  },
  {
    "line1": "Why did the golfer wear two pairs of pants?",
    "line2": "Just in case he got a hole in one!"
  },
  {
    "line1": "Why don’t the circus lions eat the clowns?",
    "line2": "Because they taste funny!"
  },
  {
    "line1": "Which is faster, hot or cold?",
    "line2": "Hot, because you can catch cold."
  },
  {
    "line1": "What did one plate say to the other plate?",
    "line2": "Dinner's on me."
  },
  {
    "line1": "Why do oranges wear sunscreen?",
    "line2": "So they don't peel."
  },
  {
    "line1": "My wife told me to stop acting like a flamingo,",
    "line2": "so I had to put my foot down."
  },
  {
    "line1": "What do you call a pig that does karate?",
    "line2": "A pork chop."
  },
  {
    "line1": "Where does Batman go to the bathroom?",
    "line2": "The batroom."
  },
  {
    "line1": "What do you call a pony with a sore throat?",
    "line2": "A little horse."
  },
  {
    "line1": "What did the left eye say to the right eye?",
    "line2": "Between you and me, something smells."
  },
  {
    "line1": "What did the mama tomato say to the baby tomato?",
    "line2": "Catch up!"
  },
  {
    "line1": "Why didn't the melons get married?",
    "line2": "Because they cantaloupe."
  },
  {
    "line1": "What do you call a fake noodle?",
    "line2": "An impasta."
  },
  {
    "line1": "How did the pig get to the hogspital?",
    "line2": "In a hambulance."
  },
  {
    "line1": "I'm so good at sleeping I can do it with my eyes closed!"
  },
  {
    "line1": "Why does Humpty Dumpty love autumn?",
    "line2": "Because he had a great fall."
  },
  {
    "line1": "What happens when a strawberry gets run over crossing the street?",
    "line2": "Traffic jam."
  },
  {
    "line1": "Why did the cow jump over the moon?",
    "line2": "The farmer had cold hands."
  },
  {
    "line1": "A termite walks into a bar and says,",
    "line2": "So, is the bar tender here?"
  },
  {
    "line1": "How does an octopus go into battle?",
    "line2": "Well-armed."
  },
  {
    "line1": "What do you call a pudgy psychic?",
    "line2": "A four-chin teller."
  },
  {
    "line1": "What do you get when you mix a cocker spaniel, a poodle, and a ghost?",
    "line2": "A cocker-poodle boo."
  },
  {
    "line1": "How do celebrities stay cool?",
    "line2": "They have many fans."
  },
  {
    "line1": "What does a pickle say when he wants to play cards?",
    "line2": "\"Dill me in!\""
  },
  {
    "line1": "How much money does a pirate pay for corn?",
    "line2": "A buccaneer."
  },
  {
    "line1": "Where do young trees go to learn?",
    "line2": "Elementree school."
  },
  {
    "line1": "Why do bees have sticky hair?",
    "line2": "Because they use a honeycomb."
  },
  {
    "line1": "How did the student feel when he learned about electricity?",
    "line2": "Totally shocked."
  },
  {
    "line1": "What do you call a bee that can't make up its mind?",
    "line2": "A Maybe."
  },
  {
    "line1": "Why was six afraid of seven?",
    "line2": "Because 7-8-9."
  },
  {
    "line1": "If April showers bring May flowers, what do May flowers bring?",
    "line2": "Pilgrims."
  },
  {
    "line1": "I tried to catch fog yesterday.",
    "line2": "Mist."
  },
  {
    "line1": "What do you call a hippie's wife?",
    "line2": "Mississippi."
  },
  {
    "line1": "Two peanuts were walking down the street.",
    "line2": "One was a-salted."
  },
  {
    "line1": "How can you tell it’s a dogwood tree?",
    "line2": "By the bark."
  },
  {
    "line1": "What did the buffalo say when his kid went to college?",
    "line2": "Bison."
  },
  {
    "line1": "What did the mayonnaise say when the refrigerator door was opened?",
    "line2": "Close the door, I'm dressing."
  },
  {
    "line1": "What's the stinkiest planet?",
    "line2": "Poopiter."
  },
  {
    "line1": "What did one wall say to the other?",
    "line2": "I'll meet you at the corner."
  },
  {
    "line1": "Why don't sharks eat clowns?",
    "line2": "Because they taste funny."
  },
  {
    "line1": "A horse walks into a bar.",
    "line2": "The bartender says, 'Why the long face?'"
  },
  {
    "line1": "What did the pirate say when he turned 80?",
    "line2": "Aye matey."
  },
  {
    "line1": "What's black and white and goes round and round?",
    "line2": "A penguin in the washing machine."
  },
  {
    "line1": "How do you organize a space party?",
    "line2": "You planet."
  },
  {
    "line1": "Why couldn't the bicycle stand up by itself?",
    "line2": "It was two tired."
  },
  {
    "line1": "Did you hear the rumor about butter?",
    "line2": "Well, I'm not going to spread it."
  },
  {
    "line1": "Why did the student eat his homework?",
    "line2": "Because his teacher told him it was a piece of cake."
  },
  {
    "line1": "What did one hat say to the other?",
    "line2": "You wait here, I'll go on ahead."
  },
  {
    "line1": "What do you call a dinosaur that crashes his car?",
    "line2": "Tyrannosaurus Wrecks."
  },
  {
    "line1": "I couldn't figure out why the baseball kept getting larger.",
    "line2": "Then it hit me."
  },
  {
    "line1": "What do you call a boomerang that won’t come back?",
    "line2": "A stick."
  },
  {
    "line1": "What did the full glass say to the empty glass?",
    "line2": "You look drunk."
  },
  {
    "line1": "How do you stop a bull from charging?",
    "line2": "Cancel its credit card."
  },
  {
    "line1": "Why don’t we see elephants hiding in trees?",
    "line2": "Because they’re really good at it."
  },
  {
    "line1": "What falls, but never needs a bandage?",
    "line2": "The rain."
  },
  {
    "line1": "I was going to tell you a joke about boxing but I forgot the punch line."
  },
  {
    "line1": "I'm not a fan of spring cleaning.",
    "line2": "Let's be honest, I'm not into summer, fall, or winter cleaning either."
  },
  {
    "line1": "Why did the egg hide?",
    "line2": "It was a little chicken."
  },
  {
    "line1": "What did the dirt say to the rain?",
    "line2": "If you keep this up, my name will be mud!"
  },
  {
    "line1": "Why couldn't the sunflower ride its bike?",
    "line2": "It lost its petals."
  },
  {
    "line1": "What's an egg's favorite vacation spot?",
    "line2": "New Yolk City."
  },
  {
    "line1": "I ate a sock yesterday.",
    "line2": "It was very time-consuming."
  },
  {
    "line1": "What kind of candy do astronauts like?",
    "line2": "Mars bars."
  },
  {
    "line1": "I wanted to buy some camo pants but couldn't find any."
  },
  {
    "line1": "I ordered a chicken and an egg from Amazon.",
    "line2": "I'll let you know."
  },
  {
    "line1": "What month is the shortest of the year?",
    "line2": "May, it only has three letters."
  },
  {
    "line1": "What did the snail who was riding on the turtle's back say?",
    "line2": "Wheeeee!"
  },
  {
    "line1": "I was going to tell a time traveling joke, but you guys didn't like it."
  },
  {
    "line1": "What do you call a lazy kangaroo?",
    "line2": "A pouch potato."
  },
  {
    "line1": "I used to run a dating service for chickens, but I was struggling to make hens meet."
  },
  {
    "line1": "Why do we tell actors to 'break a leg?'",
    "line2": "Because every play has a cast."
  },
  {
    "line1": "What does a pig put on dry skin?",
    "line2": "Oinkment."
  },
  {
    "line1": "What do you call it when a snowman throws a tantrum?",
    "line2": "A meltdown."
  },
  {
    "line1": "My uncle named his dogs Timex and Rolex.",
    "line2": "They're his watch dogs."
  },
  {
    "line1": "Did you hear about the guy whose left side was cut off?",
    "line2": "He's all right now."
  },
  {
    "line1": "How do you open a banana?",
    "line2": "With a mon-key."
  },
  {
    "line1": "Why should you never throw grandpa's false teeth at a vehicle?",
    "line2": "You might denture car."
  },
  {
    "line1": "Why are Christmas trees bad at knitting?",
    "line2": "They always drop their needles."
  },
  {
    "line1": "What did the lunch box say to the refrigerator?",
    "line2": "Don't hate me because I'm a little cooler."
  },
  {
    "line1": "I can always tell when someone is lying.",
    "line2": "I can tell when they're standing too."
  },
  {
    "line1": "What's the coolest element in the periodic table?",
    "line2": "Ber-yllium"
  },
  {
    "line1": "I used to be afraid of speed bumps.",
    "line2": "I'm trying to get over it."
  },
  {
    "line1": "If your house is cold, just stand in the corner.",
    "line2": "It’s always 90 degrees there."
  },
  {
    "line1": "I don't recommend entering a wormhole.",
    "line2": "You might get stuck in the apple."
  },
  {
    "line1": "The owner of the tuxedo store kept hovering over me when i was browsing, so I asked him to leave me alone.",
    "line2": "He said, 'Fine, suit yourself.'"
  },
  {
    "line1": "Why did the egg have a day off?",
    "line2": "Because it was Fryday."
  },
  {
    "line1": "Have you ever heard about the kidnapping at school?",
    "line2": "It's okay, he woke up."
  },
  {
    "line1": "I found a book called How to Solve 50% of Your Problems.",
    "line2": "So I bought 2."
  },
  {
    "line1": "What do Keanu Reeves and baby Yoda have in common?",
    "line2": "They age at the same rate."
  },
  {
    "line1": "Why did the coffee taste like dirt?",
    "line2": "Because it was ground just a few minutes ago."
  },
  {
    "line1": "Why can't you make a dinosaur omelet?",
    "line2": "Because they're egg-stinct."
  },
  {
    "line1": "Why do quarterbacks tell obvious jokes?",
    "line2": "So they don't go over their receivers' heads."
  },
  {
    "line1": "Why did the Rolling Stones stop making music?",
    "line2": "Because they got to bottom of the hill."
  },
  {
    "line1": "How many goats does it take to change a lightbulb?",
    "line2": "Just one, but you have to goat them into it."
  },
  {
    "line1": "What did one cupcake say to the other?",
    "line2": "You ain't see muffin yet."
  },
  {
    "line1": "What is the best present?",
    "line2": "Broken drums! You can't beat them."
  },
  {
    "line1": "Did you hear about the new squirrel diet?",
    "line2": "It's just nuts."
  },
  {
    "line1": "I made song about tortilla once, now it's more like a wrap.",
    "line2": "Did you hear about the spatula's hot new flame? It met the grill of its dreams."
  },
  {
    "line1": "Did you know corduroy pillows are in style?",
    "line2": "They're making headlines."
  },
  {
    "line1": "What does a nosey pepper do?",
    "line2": "It gets jalapeño business."
  },
  {
    "line1": "Did you hear about the fragile myth?",
    "line2": "It was busted."
  },
  {
    "line1": "Did you hear about the pirate who became a great chef?",
    "line2": "He mastered the seven sea-sonings."
  },
  {
    "line1": "What word can you make shorter by adding two letters?",
    "line2": "Short."
  },
  {
    "line1": "When does a hippo have a tusk?",
    "line2": "After some rhino-plasty."
  },
  {
    "line1": "What do call a criminal landing an airplane?",
    "line2": "Condescending."
  },
  {
    "line1": "I decided to sell my vacuum cleaner.",
    "line2": "It was just gathering dust."
  },
  {
    "line1": "I stayed up all night wondering where the sun went, and then it dawned on me.",
    "line2": "Why do people who live in Greece hate waking up at dawn? Because Dawn is tough on Greece."
  },
  {
    "line1": "What's the best thing about Switzerland?",
    "line2": "The flag is a big plus."
  },
  {
    "line1": "I went to the aquarium this weekend, but I didn’t stay long.",
    "line2": "There’s something fishy about that place."
  },
  {
    "line1": "I found a lion in my closet the other day! When I asked what it was doing there, it said 'Narnia business'.",
    "line2": "What's a cat's favorite instrument? Purr-cussion."
  },
  {
    "line1": "Why did the snail paint a giant S on his car?",
    "line2": "So when he drove by, people could say: 'Look at that S car go!'"
  },
  {
    "line1": "What do you call a happy cowboy?",
    "line2": "A jolly rancher."
  },
  {
    "line1": "What subject do cats like best in school?",
    "line2": "Hiss-tory."
  },
  {
    "line1": "Humpty Dumpty had a great fall.",
    "line2": "He said his summer was pretty good too."
  },
  {
    "line1": "My boss said 'dress for the job you want, not for the job you have.'",
    "line2": "So I went in as Batman."
  },
  {
    "line1": "How do you make holy water?",
    "line2": "You boil the hell out of it."
  },
  {
    "line1": "Justice is a dish best served cold.",
    "line2": "Otherwise, it's just water."
  },
  {
    "line1": "What kind of pictures do turtles take?",
    "line2": "Shell-fies."
  },
  {
    "line1": "Why did the golfer wear two pairs of pants while he played?",
    "line2": "In case he got a hole in one."
  },
  {
    "line1": "What was the first animal in space?",
    "line2": "The cow that jumped over the moon."
  },
  {
    "line1": "What do you call a famous turtle?",
    "line2": "A shell-ebrity!"
  },
  {
    "line1": "Why are penguins socially awkward?",
    "line2": "Because they don’t know how to break the ice."
  },
  {
    "line1": "What did the banana say to the dog?",
    "line2": "Nothing. Bananas can’t talk."
  },
  {
    "line1": "What type of key opens a banana?",
    "line2": "A mon-key."
  },
  {
    "line1": "What do you feed an alligator?",
    "line2": "Anything it wants!"
  },
  {
    "line1": "How does a cucumber become a pickle?",
    "line2": "It goes through a jarring experience."
  },
  {
    "line1": "What time is it when the clock strikes 13?",
    "line2": "Time to get a new clock."
  },
  {
    "line1": "How are false teeth like stars?",
    "line2": "They come out at night."
  },
  {
    "line1": "Why was the student’s report card wet?",
    "line2": "It was below C level."
  },
  {
    "line1": "What kind of pizza do dogs eat?",
    "line2": "Pup-eroni pizza!"
  },
  {
    "line1": "What do Olympic sprinters eat before a race?",
    "line2": "Nothing, they fast!"
  },
  {
    "line1": "What’s worse than finding a worm in your apple?",
    "line2": "Finding half a worm."
  },
  {
    "line1": "Which side of a cow is the hairiest?",
    "line2": "The outside!"
  },
  {
    "line1": "What do cats wear to bed?",
    "line2": "Paw-jamas!"
  },
  {
    "line1": "Why do bowling pins have such a hard life?",
    "line2": "They’re always getting knocked down."
  },
  {
    "line1": "What is a computer's favorite snack?",
    "line2": "Computer chips."
  },
  {
    "line1": "Why did the M&M go to school?",
    "line2": "Because she wanted to be a Smartie."
  },
  {
    "line1": "What’s a cat’s favorite color?",
    "line2": "Purrr-ple."
  },
  {
    "line1": "Where do cows go on December 31st?",
    "line2": "A moo year’s eve party."
  },
  {
    "line1": "What do you call two birds in love?",
    "line2": "Tweethearts."
  },
  {
    "line1": "Why are ghosts terrible liars?",
    "line2": "Because you can see right through them."
  },
  {
    "line1": "What does a triceratops sit on?",
    "line2": "On its tricera-bottom."
  },
  {
    "line1": "What did the snowman ask the other snowman?",
    "line2": "“Do you smell carrots?”"
  },
  {
    "line1": "How does a scientist freshen her breath?",
    "line2": "With experi-mints."
  },
  {
    "line1": "Why are elevator jokes the funniest?",
    "line2": "Because they work on so many levels."
  },
  {
    "line1": "What do you call a sleeping T-Rex?",
    "line2": "A dino-snore!"
  },
  {
    "line1": "What did the fisherman say to the magician?",
    "line2": "“Pick a cod, any cod.”"
  },
  {
    "line1": "What is the strongest animal in the sea?",
    "line2": "Mussels."
  },
  {
    "line1": "Why didn’t the lamp sink?",
    "line2": "It was too light."
  },
  {
    "line1": "What building in New York has the most stories?",
    "line2": "The public library."
  },
  {
    "line1": "What has three letters and starts with gas?",
    "line2": "A car."
  },
  {
    "line1": "How does the moon cut his hair?",
    "line2": "Eclipse it."
  },
  {
    "line1": "What did the elf learn in school?",
    "line2": "The elf-abet."
  },
  {
    "line1": "What kind of chicken is the funniest?",
    "line2": "A comedi-hen!"
  },
  {
    "line1": "Why do vampires seem sick?",
    "line2": "They’re always coffin."
  },
  {
    "line1": "How do you get a squirrel to like you?",
    "line2": "Act like a complete nut!"
  },
  {
    "line1": "Why did the farmer ride his horse into town?",
    "line2": "Because it was too heavy to carry."
  },
  {
    "line1": "What animal is always at a baseball game?",
    "line2": "A bat."
  },
  {
    "line1": "How can you tell if an ant is a boy or a girl?",
    "line2": "They’re all girls! If they were boys, they’d be uncles."
  },
  {
    "line1": "Why did the teacher have a sack full of birdseed?",
    "line2": "For her parrot-teacher conferences."
  },
  {
    "line1": "What kind of music do mummies listen to?",
    "line2": "Wrap music."
  },
  {
    "line1": "What falls in winter but never gets hurt?",
    "line2": "Snow."
  },
  {
    "line1": "How many months have 28 days?",
    "line2": "All of them!"
  },
  {
    "line1": "Why was the broom late to school?",
    "line2": "It over-swept!"
  },
  {
    "line1": "What kind of nut doesn’t like money?",
    "line2": "Cash ew."
  },
  {
    "line1": "What do you call a ghost’s true love?",
    "line2": "A ghoul-friend."
  },
  {
    "line1": "What’s white and can’t climb trees?",
    "line2": "A fridge."
  },
  {
    "line1": "Who eats snails?",
    "line2": "People who don’t like fast food!"
  },
  {
    "line1": "What did the bathtub say to the toilet?",
    "line2": "“You look flushed!”"
  },
  {
    "line1": "How much does it cost a pirate to get his ears pierced?",
    "line2": "A buck an ear."
  },
  {
    "line1": "What was a more useful invention than the first telephone?",
    "line2": "The second telephone."
  },
  {
    "line1": "Why did the banana visit the doctor?",
    "line2": "She wasn’t peeling well!"
  },
  {
    "line1": "What’s a snake’s favorite subject in school?",
    "line2": "Hiss-tory."
  },
  {
    "line1": "How do you talk to a giant?",
    "line2": "Use big words."
  },
  {
    "line1": "What did the bald man say when he received a comb for his birthday?",
    "line2": "“I’ll never part with this!”"
  },
  {
    "line1": "Why did the computer get sick?",
    "line2": "It caught a virus!"
  },
  {
    "line1": "Why did the God of Thunder need to stretch his leg muscles so much as a kid?",
    "line2": "He was a little Thor."
  },
  {
    "line1": "Why was the baby strawberry crying?",
    "line2": "Because their parents were in a jam."
  },
  {
    "line1": "Why don’t you ever see giraffes in middle school?",
    "line2": "Because they’re all in high school."
  },
  {
    "line1": "How do bees brush their hair?",
    "line2": "With honeycombs!"
  },
  {
    "line1": "What do you do if someone rolls their eyes at you?",
    "line2": "Roll them right back."
  },
  {
    "line1": "What did the Baby Corn say to the Mama Corn?",
    "line2": "“Where is Pop Corn?”"
  },
  {
    "line1": "What gets wetter the more it dries?",
    "line2": "A towel."
  },
  {
    "line1": "Why won’t peanut butter tell you a secret?",
    "line2": "He’s afraid you’ll spread it!"
  },
  {
    "line1": "What’s the difference between a car and a fish?",
    "line2": "You can tune a car but you can’t tuna fish."
  },
  {
    "line1": "What is worse than raining cats and dogs?",
    "line2": "Hailing taxis."
  },
  {
    "line1": "What do you call an old snowman?",
    "line2": "A glass of water."
  },
  {
    "line1": "What do you call cheese that belongs to someone else?",
    "line2": "Nacho cheese!"
  },
  {
    "line1": "Why are pizza jokes the worst?",
    "line2": "They're too cheesy."
  },
  {
    "line1": "What kind of water cannot freeze?",
    "line2": "Hot water."
  },
  {
    "line1": "Why was the belt arrested?",
    "line2": "Because it was holding up some pants."
  },
  {
    "line1": "Why did the peanut get into a rocket?",
    "line2": "He wanted to be an astro-nut!"
  },
  {
    "line1": "Did you hear the joke about the roof?",
    "line2": "Never mind, it would go over your head."
  },
  {
    "line1": "Why did the cookie go to the hospital?",
    "line2": "Because it was feeling a little crummy."
  },
  {
    "line1": "What did the ghost call his Mum and Dad?",
    "line2": "His transparents."
  },
  {
    "line1": "What fruit do twins love?",
    "line2": "Pears!"
  },
  {
    "line1": "What time is it when people are throwing pieces of bread at your head?",
    "line2": "Time to duck."
  },
  {
    "line1": "What did the limestone say to the geologist?",
    "line2": "Don't take me for granite!"
  },
  {
    "line1": "If an electric train is traveling south at 10 miles per hour and the wind is blowing North at 10 miles per hour, which way does the smoke blow?",
    "line2": "Fooled you! Electric trains don’t blow smoke."
  },
  {
    "line1": "Why are basketball courts always wet?",
    "line2": "Because the players dribble."
  },
  {
    "line1": "How do billboards talk?",
    "line2": "Sign language."
  },
  {
    "line1": "What do you call a duck that gets good grades?",
    "line2": "A wise quacker."
  },
  {
    "line1": "What do you call a fish without an eye?",
    "line2": "A fsh."
  },
  {
    "line1": "What kind of keys are sweet?",
    "line2": "Cookies!"
  },
  {
    "line1": "What board game does the sky love to play?",
    "line2": "Twister."
  },
  {
    "line1": "Why does a seagull fly over the sea?",
    "line2": "Because if it flew over the bay, it would be a baygull."
  },
  {
    "line1": "What do you call a tired bull?",
    "line2": "A bulldozer."
  },
  {
    "line1": "Why do ducks always pay with cash?",
    "line2": "Because they always have bills!"
  },
  {
    "line1": "How did Benjamin Franklin feel holding his kite when he discovered electricity?",
    "line2": "Shocked!"
  },
  {
    "line1": "When does a joke become a 'dad' joke?",
    "line2": "When the punchline is a parent."
  },
  {
    "line1": "How much did the man sell his dead batteries for?",
    "line2": "Nothing, they were free of charge!"
  },
  {
    "line1": "Where do most horses live?",
    "line2": "In neighhh-borhoods!"
  },
  {
    "line1": "Why did the Daddy Rabbit go to the barber?",
    "line2": "He had a lot of little hares."
  },
  {
    "line1": "How do you make a lemon drop?",
    "line2": "Let it fall from the tree."
  },
  {
    "line1": "Why can’t you ever trust atoms?",
    "line2": "Because they make up everything."
  },
  {
    "line1": "Which planet loves to sing?",
    "line2": "Nep-tune!"
  },
  {
    "line1": "What’s a private investigator’s favorite shoe?",
    "line2": "Sneak-ers."
  },
  {
    "line1": "What do you call a Star Wars droid that takes the long way around?",
    "line2": "R2 detour."
  },
  {
    "line1": "How do you stop an astronaut’s baby from crying?",
    "line2": "You rocket."
  },
  {
    "line1": "Why did the scarecrow win a Nobel prize?",
    "line2": "Because she was outstanding in her field."
  },
  {
    "line1": "How do you know when a bike is thinking?",
    "line2": "You can see their wheels turning."
  },
  {
    "line1": "Why was 6 afraid of 7?",
    "line2": "Because 7,8,9."
  },
  {
    "line1": "What goes up and down but doesn’t move?",
    "line2": "The staircase."
  },
  {
    "line1": "What kind of shoes do frogs love?",
    "line2": "Open-toad!"
  },
  {
    "line1": "How did the baby tell his mom he had a wet diaper?",
    "line2": "He sent her a pee-mail."
  },
  {
    "line1": "What is a witch’s favorite subject in school?",
    "line2": "Spelling."
  },
  {
    "line1": "What’s brown and sticky?",
    "line2": "A stick."
  },
  {
    "line1": "Why is a football stadium always cold?",
    "line2": "It has lots of fans!"
  },
  {
    "line1": "What did one math book say to the other?",
    "line2": "“I’ve got so many problems.”"
  },
  {
    "line1": "What did the Dalmatian say after lunch?",
    "line2": "That hit the spot!"
  },
  {
    "line1": "What do you call two bananas on the floor?",
    "line2": "Slippers."
  },
  {
    "line1": "Why did the chicken cross the playground?",
    "line2": "To get to the other slide."
  },
  {
    "line1": "Why do ducks have feathers on their tails?",
    "line2": "To cover their butt quacks."
  },
  {
    "line1": "How does a vampire start a letter?",
    "line2": "“Tomb it may concern…”"
  },
  {
    "line1": "A plane crashed in the jungle and every single person died.",
    "line2": "Who survived? Married couples."
  },
  {
    "line1": "What kind of math do birds love?",
    "line2": "Owl-gebra!"
  },
  {
    "line1": "Why can’t you ever tell a joke around glass?",
    "line2": "It could crack up."
  },
  {
    "line1": "Why did the student eat his homework?",
    "line2": "Because the teacher told him it was a piece of cake."
  },
  {
    "line1": "What did Yoda say when he saw himself in 4k?",
    "line2": "HDMI."
  },
  {
    "line1": "Which superhero hits home runs?",
    "line2": "Batman!"
  },
  {
    "line1": "What’s Thanos’ favorite app on his phone?",
    "line2": "Snapchat."
  },
  {
    "line1": "Sandy’s mum has four kids; North, West, East.",
    "line2": "What is the name of the fourth child? Sandy, obviously!"
  },
  {
    "line1": "What is a room with no walls?",
    "line2": "A mushroom."
  },
  {
    "line1": "Why did the blue jay get in trouble at school?",
    "line2": "For tweeting on a test!"
  },
  {
    "line1": "What social events do spiders love to attend?",
    "line2": "Webbings."
  },
  {
    "line1": "What did one pickle say to the other?",
    "line2": "Dill with it."
  },
  {
    "line1": "What is brown, hairy and wears sunglasses?",
    "line2": "A coconut on vacation."
  },
  {
    "line1": "Name the kind of tree you can hold in your hand?",
    "line2": "A palm tree!"
  },
  {
    "line1": "What do birds give out on Halloween?",
    "line2": "Tweets."
  },
  {
    "line1": "What has ears but cannot hear?",
    "line2": "A cornfield."
  },
  {
    "line1": "What’s a cat’s favorite dessert?",
    "line2": "A bowl full of mice-cream."
  },
  {
    "line1": "Where did the music teacher leave her keys?",
    "line2": "In the piano!"
  },
  {
    "line1": "What did the policeman say to his hungry stomach?",
    "line2": "“Freeze. You’re under a vest.”"
  },
  {
    "line1": "What did the left eye say to the right eye?",
    "line2": "Between us, something smells!"
  },
  {
    "line1": "What do you call a guy who’s really loud?",
    "line2": "Mike."
  },
  {
    "line1": "Why do birds fly south in the winter?",
    "line2": "It’s faster than walking!"
  },
  {
    "line1": "What did the lava say to his girlfriend?",
    "line2": "“I lava you!”"
  },
  {
    "line1": "What do kids play when their mom is using the phone?",
    "line2": "Bored games."
  },
  {
    "line1": "What do you call an ant who fights crime?",
    "line2": "A vigilANTe!"
  },
  {
    "line1": "Why are snails slow?",
    "line2": "Because they’re carrying a house on their back."
  },
  {
    "line1": "What’s the smartest insect?",
    "line2": "A spelling bee!"
  },
  {
    "line1": "What does a storm cloud wear under his raincoat?",
    "line2": "Thunderwear."
  },
  {
    "line1": "What is fast, loud and crunchy?",
    "line2": "A rocket chip."
  },
  {
    "line1": "How does the ocean say hi?",
    "line2": "It waves!"
  },
  {
    "line1": "What do you call a couple of chimpanzees sharing an Amazon account?",
    "line2": "PRIME-mates."
  },
  {
    "line1": "Why did the teddy bear say no to dessert?",
    "line2": "Because she was stuffed."
  },
  {
    "line1": "Why did the soccer player take so long to eat dinner?",
    "line2": "Because he thought he couldn’t use his hands."
  }
]

export default jokes